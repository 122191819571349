import React, { useState } from "react"
import { DashboardHeroContainer } from "./Hero.styles"
import { useDocument } from "../../hooks/useDocument"

const DashboardHero = ({ user,arabic }) => {
  const [document, setDocument] = useState("")
  useDocument("users", user.uid).then(document => {
    if (document.data) {
      setDocument(document)
    }
  })

  return (
    document && (
      <DashboardHeroContainer>
        <div className={arabic===false? "container":"container text-end"}>
          <h1>{arabic===false ? 'Welcome':'أهلا وسهلا'} {document.data.first_name}</h1>
          <p>{arabic===false ? 'Account Number :':'رقم حساب :'} {document.id}</p>
          <p>
            {arabic=== false ? ' Member Since :':'عضو منذ:'}
            {document.data.createdAt.toDate().toDateString()}
          </p>
        </div>
      </DashboardHeroContainer>
    )
  )
}

export default DashboardHero
