import { deleteDoc, doc, where, orderBy } from "firebase/firestore"
import { deleteObject, ref } from "firebase/storage"
import { Link } from "gatsby"
import React, { useState } from "react"
import { storage, db } from "../../firebase/config"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useCollection } from "../../hooks/useCollection"
import Loader from "../Loader"

import {
  ListingsContainer,
  RightContent,
  ToBeDeletedPopup,
} from "./Dashboard.styles"

const Listings = ({ arabic }) => {
  const [listings, setListings] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [toBeDeleted, setToBeDeleted] = useState(null)

  const { user } = useAuthContext()
  useCollection("property", ["added_by", "==", user.uid]).then(
    fetchedListings => {
      setListings(fetchedListings)
    }
  )

  const handleDeleteListing = () => {
    setIsLoading(true)
    // declaring deletion of the docment
    const deleteDocument = () => {
      deleteDoc(doc(db, "property", toBeDeleted.id)).then(() => {
        setIsLoading(false)
        window.location = "/dashboard"
      })
    }

    const featuredImage = toBeDeleted.data.featured_image
    const gallery = toBeDeleted.data.gallery

    // deleting the thumbnail first
    const thumbRef = ref(storage, featuredImage.reference)
    deleteObject(thumbRef).then(() => {
      // deleting the gallery images if any
      if (gallery.length !== 0) {
        let count = 0
        gallery.forEach(image => {
          const imageRef = ref(storage, image.reference)
          deleteObject(imageRef).then(() => {
            count++
            if (count === gallery.length) {
              deleteDocument()
            }
          })
        })
      } else {
        deleteDocument()
      }
    })
  }
  return (
    <RightContent>
      {isLoading && <Loader />}
      <ListingsContainer>
        {toBeDeleted && (
          <ToBeDeletedPopup>
            <div className="content">
              <h3>
                {arabic == false
                  ? "All content related to this listing will be deleted. Are you sure?"
                  : "سيتم حذف جميع المحتويات المتعلقة بهذه القائمة. هل أنت واثق؟"}
              </h3>
              <div className="buttons">
                <button
                  className="cancel"
                  onClick={() => {
                    setToBeDeleted(null)
                  }}
                >
                  {arabic == false ? "Cancel" : "يلغي"}
                </button>
                <button className="delete" onClick={handleDeleteListing}>
                  {arabic == false ? "Delete" : "حذف"}
                </button>
              </div>
            </div>
          </ToBeDeletedPopup>
        )}
        <h3 className={arabic == false ? "" : "text-end"}>
          {arabic == false ? "My Listing" : "قائمتي"}
        </h3>
        <p className={arabic == false ? "subheading" : "subheading text-end"}>
          {arabic == false
            ? "You can view property listings that you have recently posted or you can add a new listing"
            : "يمكنك عرض قوائم العقارات التي قمت بنشرها مؤخرًا أو يمكنك إضافة قائمة جديدة"}
        </p>
        <Link to="/add-listing" className="add">
          {arabic == false ? "ADD LISTING" : "إضافة قائمة"}
        </Link>
        <div className="listings">
          {listings &&
            listings.map(listing => {
              const data = listing.data
              return (
                <div className="listing" key={listing.id}>
                  <div className="info">
                    <img
                      src={data.featured_image.url}
                      alt="listing"
                      className="featured-img"
                      width={250}
                    />
                    <div className="text">
                      <h5>
                        <Link to={`/property/${listing.id}`}>{data.title}</Link>
                      </h5>
                      <p className="location">
                        {data.address || data.street_name}, {data.country.label}
                      </p>
                      {data.num_bedrooms > 0 && (
                        <div className="amenities">
                          {data.num_bedrooms}{" "}
                          {arabic == false ? "Beds" : "سرير"} |{" "}
                          {data.num_bathrooms}{" "}
                          {arabic == false ? "Bath" : "حمام"} | {data.area}{" "}
                          {arabic == false ? "m" : " مترًا مربعًا"}
                          <sup>2</sup>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="edit">
                    <Link to="/edit-listing" state={{ listing: listing }}>
                      {arabic == false ? "EDIT LISTING" : "تحرير الإدراج"}
                    </Link>
                    <button
                      className="delete"
                      onClick={() => setToBeDeleted(listing)}
                    >
                      {arabic == false ? "DELETE LISTING" : "حذف القائمة"}
                    </button>
                  </div>
                </div>
              )
            })}
        </div>
      </ListingsContainer>
    </RightContent>
  )
}

export default Listings
