import React from 'react'

const InboxIcon = ({isActive}) => {
  if(isActive){
    return(
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.42"
      height="14.701"
      viewBox="0 0 16.42 14.701"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#1d8cc3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.8"
        transform="translate(.4 .4)"
      >
        <path
          d="M12.4 4.516L8.935 7.305a1.806 1.806 0 01-2.23 0L3.211 4.516"
          data-name="Path 178"
        ></path>
        <path
          d="M3.97 0h7.657a4.026 4.026 0 012.908 1.292A4.075 4.075 0 0115.612 4.3v5.3a4.075 4.075 0 01-1.077 3.008 4.026 4.026 0 01-2.908 1.292H3.97A4.035 4.035 0 010 9.6V4.3A4.035 4.035 0 013.97 0z"
          data-name="Path 179"
        ></path>
      </g>
    </svg>
    )
  } else {
    return(
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.42"
      height="14.701"
      viewBox="0 0 16.42 14.701"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#200e32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.8"
        transform="translate(.4 .4)"
      >
        <path
          d="M12.4 4.516L8.935 7.305a1.806 1.806 0 01-2.23 0L3.211 4.516"
          data-name="Path 178"
        ></path>
        <path
          d="M3.97 0h7.657a4.026 4.026 0 012.908 1.292A4.075 4.075 0 0115.612 4.3v5.3a4.075 4.075 0 01-1.077 3.008 4.026 4.026 0 01-2.908 1.292H3.97A4.035 4.035 0 010 9.6V4.3A4.035 4.035 0 013.97 0z"
          data-name="Path 179"
        ></path>
      </g>
    </svg>
    )
  }
}

export default InboxIcon