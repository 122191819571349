import React from "react"

const PersonalIcon = ({ isActive }) => {
  if (isActive) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <g
          fill="none"
          stroke="#1d8cc3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(-7.5 -7.5)"
        >
          <path
            d="M18.778 13.848c0 1.238-1 3.4-2.241 3.4s-2.241-2.159-2.241-3.4a2.241 2.241 0 014.478 0zm-6.865 9.697a4.624 4.624 0 019.248 0"
            data-name="Path 153"
          ></path>
          <circle
            cx="8.5"
            cy="8.5"
            r="8.5"
            data-name="Ellipse 28"
            transform="translate(8 8)"
          ></circle>
        </g>
      </svg>
    )
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <g
          fill="none"
          stroke="#1f1f1f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(-7.5 -7.5)"
        >
          <path
            d="M18.778 13.848c0 1.238-1 3.4-2.241 3.4s-2.241-2.159-2.241-3.4a2.241 2.241 0 014.478 0zm-6.865 9.697a4.624 4.624 0 019.248 0"
            data-name="Path 153"
          ></path>
          <circle
            cx="8.5"
            cy="8.5"
            r="8.5"
            data-name="Ellipse 28"
            transform="translate(8 8)"
          ></circle>
        </g>
      </svg>
    )
  }
}

export default PersonalIcon
