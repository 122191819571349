import React, { useState } from "react"
import { useDocument } from "../../hooks/useDocument"
import { ListingsContainer, RightContent } from "./Dashboard.styles"
import LikedProperty from "./liked_property"

const LikedProperties = ({ userId,arabic }) => {
  const [listings, setListings] = useState([])
  useDocument("users", userId).then(document => {
    if (document.data) {
      setListings(document.data.liked_properties)
    }
  })
  return (
    <RightContent>
      <ListingsContainer>
        <h3 className={arabic==false?'':'text-end'}>{arabic==false ?'My Favorite Listings':'قوائمي المفضلة'}</h3>
        <p className={arabic==false?'subheading':'subheading text-end'}>{arabic==false ? 'You can view your favorite lisitngs here':'يمكنك عرض تراخيصك المفضلة هنا'}</p>
        <div className="listings">
          {listings &&
            listings.map(property => {
              return <LikedProperty arabic={arabic} key={property} propertyId={property} />
            })}
        </div>
      </ListingsContainer>
    </RightContent>
  )
}

export default LikedProperties
