import React, { useState } from "react"

import {
  Container,
  ContentContainer,
  SidebarContainer,
  SidebarContent,
  SideBarItem,
  SidebarNotification,
  SidebarToggle,
} from "./Dashboard.styles"

// icons in dashboard
import NotifcationAlert from "../../assets/icons/dashboard/notification-alert.svg"
import Arrow from "../../assets/icons/dashboard/arrow.svg"
import InboxIcon from "../../assets/icons/dashboard/inbox.js"
import EventsIcon from "../../assets/icons/dashboard/events"
import ListingsIcon from "../../assets/icons/dashboard/listings"
import ParametersIcons from "../../assets/icons/dashboard/parameters"
import PersonalIcon from "../../assets/icons/dashboard/personal"
import PaymentIcon from "../../assets/icons/dashboard/payment"
import ContactIcon from "../../assets/icons/dashboard/contact"
import LikedPropertiesIcon from "../../assets/icons/dashboard/likedProperties"

// windows
import Notifications from "./notifications"
import Inbox from "./inbox"
import Events from "./events"
import Listings from "./listings"
import Parameters from "./parameters"
import Personal from "./personal"
import Payment from "./payment"
import Contact from "./contact"
import LikedProperties from "./likedProperties"

import { useLogout } from "../../hooks/useLogout"

const DasboardComponent = ({ user,arabic,arabic_action }) => {
  // to see if sidebar is active
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const { logout } = useLogout()

  // to activate the different windows
  const [windowActive, setWindowActive] = useState("notifications")

  // to sign out
  const signoutopd = () => {
    logout()
    document.location = "/login"
  }

  return (
    <div className="container">
      <Container>
        <SidebarContainer isOpen={sidebarOpen}>
          <h4 className={arabic==false?'':'text-end'}>{arabic==false ? 'Agent Dashboard':'لوحة معلومات الوكيل'}</h4>
          <SidebarContent>
            <SidebarToggle
              isOpen={sidebarOpen}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7.818"
                height="13.564"
                viewBox="0 0 7.818 13.564"
              >
                <path
                  id="XMLID_225_"
                  d="M12.333,6.652a.568.568,0,0,1-.8,0L6.25,1.372.97,6.652a.568.568,0,0,1-.8-.8L5.848.166a.568.568,0,0,1,.8,0l5.681,5.682A.568.568,0,0,1,12.333,6.652Z"
                  transform="translate(7.318 0.565) rotate(90)"
                  fill="#477b11"
                  stroke="#477b11"
                  strokeWidth="1"
                />
              </svg>
            </SidebarToggle>
            <SidebarNotification >
              <img src={NotifcationAlert} alt="notification alert" />
              <p className={arabic==false?'':'text-end'}>{arabic==false ? 'Notifications':'إشعارات'}</p>
              <span className={arabic==false?'text':'text text-end'}>{arabic==false ? 'View and manage your notifications':'عرض وإدارة الإشعارات الخاصة بك'}</span>
              <button onClick={() => setWindowActive("notifications")}>
                <span>{arabic==false ? 'VIEW ALL':'عرض الكل'}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.818"
                  height="13.564"
                  viewBox="0 0 7.818 13.564"
                >
                  <path
                    id="XMLID_225_"
                    d="M12.333,6.652a.568.568,0,0,1-.8,0L6.25,1.372.97,6.652a.568.568,0,0,1-.8-.8L5.848.166a.568.568,0,0,1,.8,0l5.681,5.682A.568.568,0,0,1,12.333,6.652Z"
                    transform="translate(7.318 0.565) rotate(90)"
                    fill="#477b11"
                    stroke="#477b11"
                    strokeWidth="1"
                  />
                </svg>
              </button>
            </SidebarNotification>
            <SideBarItem
              onClick={() => setWindowActive("inbox")}
              isActive={windowActive === "inbox"}
            >
              <InboxIcon isActive={windowActive === "inbox"} />
              <div className="text">
                <p>{arabic==false ?'Inbox':'صندوق الوارد'}</p>
                <span>{arabic==false ? 'Manage all received messages':'إدارة جميع الرسائل المستلمة'}</span>
              </div>
              <div className="arrow">
                <img src={Arrow} alt="arrow" />
              </div>
            </SideBarItem>
            <SideBarItem
              onClick={() => setWindowActive("events")}
              isActive={windowActive === "events"}
            >
              <EventsIcon isActive={windowActive === "events"} />
              <div className="text">
                <p>{arabic==false ? 'Events':'الأحداث'}</p>
                <span>{arabic==false ? 'Add, View and Manage your event':'إضافة وعرض وإدارة الحدث الخاص بك'}</span>
              </div>
              <div className="arrow">
                <img src={Arrow} alt="arrow" />
              </div>
            </SideBarItem>
            <SideBarItem
              onClick={() => setWindowActive("listings")}
              isActive={windowActive === "listings"}
            >
              <ListingsIcon isActive={windowActive === "listings"} />
              <div className="text">
                <p>{arabic==false ? 'Lisitngs':'القوائم'}</p>
                <span>{arabic==false ? 'Add, View and Manage your listing':'إضافة وعرض وإدارة قائمتك'}</span>
              </div>
              <div className="arrow">
                <img src={Arrow} alt="arrow" />
              </div>
            </SideBarItem>
            <SideBarItem
              onClick={() => setWindowActive("liked")}
              isActive={windowActive === "liked"}
            >
              <LikedPropertiesIcon isActive={windowActive === "liked"} />
              <div className="text">
                <p>{arabic==false ? 'Liked Listings':'قوائم الاعجاب'}</p>
                <span>{arabic==false ? 'Manage your liked listings':'إدارة قوائمك المفضلة'}</span>
              </div>
              <div className="arrow">
                <img src={Arrow} alt="arrow" />
              </div>
            </SideBarItem>
            <SideBarItem
              onClick={() => setWindowActive("parameters")}
              isActive={windowActive === "parameters"}
            >
              <ParametersIcons isActive={windowActive === "parameters"} />
              <div className="text">
                <p>{arabic==false ? 'Parameters':'المعلمات'}</p>
                <span>{arabic==false ? 'User, password, Setup':'المستخدم ، كلمة المرور ، الإعداد'}</span>
              </div>
              <div className="arrow">
                <img src={Arrow} alt="arrow" />
              </div>
            </SideBarItem>
            <SideBarItem
              onClick={() => setWindowActive("personal")}
              isActive={windowActive === "personal"}
            >
              <PersonalIcon isActive={windowActive === "personal"} />
              <div className="text">
                <p>{arabic==false ?'Personal Information':'معلومات شخصية'}</p>
                <span>{arabic==false ?'View, Edit your Personal Information':'عرض وتحرير معلوماتك الشخصية'}</span>
              </div>
              <div className="arrow">
                <img src={Arrow} alt="arrow" />
              </div>
            </SideBarItem>
            {/* <SideBarItem
              onClick={() => setWindowActive("payment")}
              isActive={windowActive === "payment"}
            >
              <PaymentIcon isActive={windowActive === "payment"} />
              <div className="text">
                <p>{arabic==false ?'Payment Method':'طريقة الدفع او السداد'}</p>
                <span>{arabic==false ? 'Add payment information':'أضف معلومات الدفع'}</span>
              </div>
              <div className="arrow">
                <img src={Arrow} alt="arrow" />
              </div>
            </SideBarItem> */}
            <SideBarItem
              onClick={() => setWindowActive("contact")}
              isActive={windowActive === "contact"}
            >
              <ContactIcon isActive={windowActive === "contact"} />
              <div className="text">
                <p>{arabic==false ? 'Contact':'اتصال'}</p>
                <span>{arabic==false ? 'Get in touch with an agent':'تواصل مع وكيل'}</span>
              </div>
              <div className="arrow">
                <img src={Arrow} alt="arrow" />
              </div>
            </SideBarItem>
          </SidebarContent>
          <button className="logout" onClick={signoutopd}>
            {arabic==false ? 'LOGOUT':'تسجيل خروج'}
            
          </button>
        </SidebarContainer>
        <ContentContainer>
          {windowActive === "notifications" && (
            <Notifications arabic={arabic} user={user} isVerified={user.emailVerified} />
          )}
          {windowActive === "inbox" && <Inbox user={user} arabic={arabic} />}
          {windowActive === "events" && <Events arabic={arabic} />}
          {windowActive === "listings" && <Listings arabic={arabic} />}
          {windowActive === "liked" && <LikedProperties arabic={arabic} userId={user.uid} />}
          {windowActive === "parameters" && <Parameters arabic={arabic} user={user} arabic_action={arabic_action}/>}
          {windowActive === "personal" && <Personal arabic={arabic} user={user}  />}
          {/* {windowActive === "payment" && <Payment arabic={arabic} />} */}
          {windowActive === "contact" && <Contact arabic={arabic} user={user} />}
        </ContentContainer>
      </Container>
    </div>
  )
}

export default DasboardComponent
