import { updateDoc, doc } from "firebase/firestore"
import React, { useState, useEffect } from "react"
import { db } from "../../firebase/config"
import { useDocument } from "../../hooks/useDocument"
import { InputContainer } from "../GlobalComponents/GlobalComponents.styles"
import Loader from "../Loader"
import {
  ParametersContainer,
  PasswordContainer,
  RightContent,
  SwitchContainer,
} from "./Dashboard.styles"

import { auth } from "../../firebase/config"
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth"
const Parameters = ({ user,arabic,arabic_action }) => {
  const [changePasswordActive, setChangePasswordActive] = useState(false)

  // for focusing
  const [currentPassFocus, setCurrentPassFocus] = useState(false)
  const [newPassFocus, setNewPassFocus] = useState(false)
  const [confirmPassFocus, setConfirmPassFocus] = useState(false)

  // for values
  const [currentPass, setCurrentPass] = useState("")
  const [newPass, setNewPass] = useState("")
  const [confirmPass, setConfirmPass] = useState("")

  const [userData, setUserData] = useState({})

  useDocument("users", user.uid).then(data => {
    if (data.data) {
      setUserData(data.data)
    }
  })

  // reset password
  const resetPassword = async () => {
    if (!currentPass) {
      alert("Please enter your current password")
    }
    if (!newPass) {
      alert("Please enter new password")
    }
    if (newPass !== confirmPass) {
      alert("The passwords do not match")
    }
    if (currentPass && newPass && newPass === confirmPass) {
      setIsLoading(true)
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPass
      )

      // resetting the fields
      const reset = () => {
        setCurrentPass("")
        setNewPass("")
        setConfirmPass("")
      }

      // reauthenticate in order to check for password
      await reauthenticateWithCredential(auth.currentUser, credential)
        .then(result => {
          // authentication is successfull
          updatePassword(auth.currentUser, newPass)
            .then(() => {
              setIsLoading(false)
              alert("Your password has been changed successfuly")
              reset()
            })
            .catch(error => {
              setIsLoading(false)
              alert(
                "There was an errror while trying to reset please try again later"
              )
              reset()
            })
        })

        // error in authentication
        .catch(error => {
          setIsLoading(false)
          alert("There was a problem trying to change password")
          reset()
        })
    }
  }

  useEffect(() => {
    setNotificationsActive(userData.recieve_notification)
    setLanguagesActive(userData.arabic_language)
    
  }, [userData])

  const [notificationsActive, setNotificationsActive] = useState(false)
  const [languagesActive, setLanguagesActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleBack = () => {
    setCurrentPass("")
    setNewPass("")
    setConfirmPass("")
    setChangePasswordActive(false)
  }

  const handleSaveParameters = () => {
    setIsLoading(true)
    updateDoc(doc(db, "users", user.uid), {
      arabic_language: languagesActive,
      recieve_notification: notificationsActive,
    }).then(() => {
      setIsLoading(false)
    })
    arabic_action(languagesActive);
  }

  return (
    <RightContent>
      {isLoading && <Loader />}
      {!changePasswordActive && (
        <ParametersContainer>
          <h3 className={arabic==false ? '':'text-end'}>{arabic==false ?'Parameters':'المعلمات'}</h3>
          <p className={arabic==false ? 'subheading':'subheading text-end'}>
            {arabic==false ? 'This section allows you to change the password, and setup the notifications and languages':'يتيح لك هذا القسم تغيير كلمة المرور وإعداد الإشعارات واللغات'}
          </p>
          <button
            className="change"
            onClick={() => setChangePasswordActive(true)}
          >
            {arabic==false? 'CHANGE PASSWORD':'غير كلمة السر'}
            
          </button>
          <div className="parameters">
            <div className="parameter">
              <div className="text">
                <p className="head">{arabic==false ? 'Notifications':'إشعارات'}</p>
                <p className="sub-head">
                  {arabic==false ? 'Would like to receive popup notifications?':'هل ترغب في تلقي الإخطارات المنبثقة؟'}
                  
                </p>
              </div>
              <SwitchContainer isActive={notificationsActive}>
                <label htmlFor="notifications">.</label>
                <input
                  type="checkbox"
                  id="notifications"
                  onChange={() => setNotificationsActive(!notificationsActive)}
                />
              </SwitchContainer>
            </div>
            <div className="parameter">
              <div className="text">
                <p className="head">{arabic==false ? 'Languages':'اللغات'}</p>
                <p className="sub-head">
                  {arabic==false ? 'Would you like to change to the language to Arabic?':'هل تود تغيير اللغة إلى العربية؟'}
                  
                </p>
              </div>
              <SwitchContainer isActive={languagesActive}>
                <label htmlFor="languages">.</label>
                <input
                  type="checkbox"
                  id="languages"
                  onChange={() => setLanguagesActive(!languagesActive)}
                />
              </SwitchContainer>
            </div>
          </div>
          <div className="button-container">
            <button onClick={handleSaveParameters}>{arabic==false ? 'SAVE':'حفظ'}</button>
          </div>
        </ParametersContainer>
      )}
      {changePasswordActive && (
        <PasswordContainer>
          <h3>{arabic==false ? 'Change Password':'غير كلمة السر'}</h3>
          <p className="subheading">{arabic==false ? 'You can change your password information':'يمكنك تغيير معلومات كلمة المرور الخاصة بك'}</p>
          <div className="password-container">
            <InputContainer isFocus={currentPassFocus}>
              <span>{arabic==false ?"Current Password":'كلمة المرور الحالية'}</span>
              <input
                type="password"
                onFocus={() => setCurrentPassFocus(true)}
                onBlur={() => setCurrentPassFocus(false)}
                value={currentPass}
                onChange={e => setCurrentPass(e.target.value)}
              />
            </InputContainer>
            <InputContainer isFocus={newPassFocus}>
              <span>{arabic==false ?'New Password':'كلمة السر الجديدة'}</span>
              <input
                type="password"
                onFocus={() => setNewPassFocus(true)}
                onBlur={() => setNewPassFocus(false)}
                value={newPass}
                onChange={e => setNewPass(e.target.value)}
              />
            </InputContainer>
            <InputContainer isFocus={confirmPassFocus}>
              <span>{arabic==false ? 'Confirm Password':'تأكيد كلمة المرور'}</span>
              <input
                type="password"
                onFocus={() => setConfirmPassFocus(true)}
                onBlur={() => setConfirmPassFocus(false)}
                value={confirmPass}
                onChange={e => setConfirmPass(e.target.value)}
              />
            </InputContainer>
            <div className="buttons">
              <button className="back" onClick={handleBack}>
                {arabic==false ? 'BACK':'الى الخلف'}
                
              </button>
              <button className="save" onClick={resetPassword}>
                {arabic==false ? 'SAVE':'حفظ'}
              </button>
            </div>
          </div>
        </PasswordContainer>
      )}
    </RightContent>
  )
}

export default Parameters
