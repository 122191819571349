import React, { useEffect, useState } from "react"
import Select from "react-select"
import {
  AddEventContainer,
  EventContainer,
  RightContent,
} from "./Dashboard.styles"
import country from "../../assets/countriesStates.json"
import LocationIcon from "../../assets/icons/location-drop.svg"
import {
  InputContainer,
  SelectContainer,
  TextareaContainer,
} from "../GlobalComponents/GlobalComponents.styles"
import { FileUploader } from "react-drag-drop-files"

import { Gallery } from "../AddListingProcess/AddListingProcess.styles"
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage"
import { db, storage } from "../../firebase/config"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore"
import Loader from "../Loader"
import { useCollection } from "../../hooks/useCollection"
import { useAuthContext } from "../../hooks/useAuthContext"

const Events = ({ arabic }) => {
  const { user } = useAuthContext()

  // select styles
  const selectStyles = {
    control: provided => ({
      ...provided,
      border: "none",
      borderColor: "transparent !important",
      boxShadow: "unset",
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "10px 0 0",
      outline: "none",
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    menu: provided => ({
      ...provided,
      zIndex: "2",
    }),
  }

  // to handle the deletion of an event
  const deleteEvent = () => {
    setIsLoading(true)
    const imageRef = ref(storage, deletedEvent.data.thumbnail.reference)

    deleteObject(imageRef)
      .then(() => {
        deleteDoc(doc(db, "event", deletedEvent.id))
          .then(() => {
            setIsLoading(false)
            setDeletedEvent(null)
            document.location = "/dashboard"
          })
          .catch(() => {
            alert("Unable to delete document")
            setIsLoading(false)
            setDeletedEvent(null)
          })
      })
      .catch(() => {
        alert("Unable to delete image")
        setIsLoading(false)
        setDeletedEvent(null)
      })
  }

  // to add a random name
  function randomName() {
    var text = ""
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text
  }

  // to handle the image upload for the event adding
  const uploadThumbnail = file => {
    const random = randomName()
    setEventThumbnail(file)

    setEventThumbnailPath(`images/event/thumbnail/${random}${file.name}`)
  }

  // to handle the delete of the images
  const deleteThumbnail = () => {
    if (eventThumbnail.name) {
      setEventThumbnail(null)
      setEventThumbnailPath("")
    } else {
      deleteObject(ref(storage, eventThumbnailPath)).then(() => {
        setEventThumbnail(null)
        setEventThumbnailPath("")
      })
    }
  }

  // handling the upload of images to firebase
  const uploadThumbnailProcess = () => {
    return new Promise(resolve => {
      if (eventThumbnail.name) {
        const reference = ref(storage, eventThumbnailPath)
        uploadBytes(reference, eventThumbnail).then(() => {
          getDownloadURL(reference).then(url => {
            resolve({ url: url, reference: eventThumbnailPath })
          })
        })
      } else {
        resolve({ url: eventThumbnail, reference: eventThumbnailPath })
      }
    })
  }

  // for adding the event
  const addEvent = async () => {
    setIsLoading(true)
    let thumbnailUpload

    // upload thumbnail first
    uploadThumbnailProcess().then(value => {
      thumbnailUpload = value

      // uploading gallery finished now add document
      const colRef = collection(db, "event")

      addDoc(colRef, {
        added_by: user.uid,
        name: name,
        date: new Date(eventDate),
        address: eventAddress,
        city: eventCity,
        country: eventCountry.label,
        topic: topic,
        thumbnail: thumbnailUpload,
        added_on: serverTimestamp(),
        id: 0,
      })

      setName("")
      setEventDate(null)
      setEventAddress("")
      setEventCity("")
      setEventCountry({})
      setTopic("")
      setEventThumbnail("")
      setEventThumbnailPath("")
      setIsLoading(false)
    })
  }

  // handling the pressing of add new event
  const handleNewEventPressed = () => {
    setName("")
    setEventDate(null)
    setEventAddress("")
    setEventCity("")
    setEventCountry({})
    setTopic("")
    setEventThumbnail("")
    setEventThumbnailPath("")
    setIsLoading(false)
    setEventEditedId(null)
    setCreateEventActive(true)
  }

  // set the event to be edited when clicking on edit
  const setEditingEvent = event => {
    setName(event.data.name)
    setEventDate(event.data.date)
    setEventAddress(event.data.address)
    setEventCity(event.data.city)
    setTopic(event.data.topic)
    setEventThumbnail(event.data.thumbnail.url)
    setEventThumbnailPath(event.data.thumbnail.reference)
    setEventEditedId(event.id)
    setCreateEventActive(true)
  }

  // handling the edit event
  const editEvent = async () => {
    setIsLoading(true)
    let thumbnailUpload
    // upload thumbnail first
    uploadThumbnailProcess().then(value => {
      thumbnailUpload = value

      // uploading gallery finished now add document
      const document = doc(db, "event", editedEventId)
      updateDoc(document, {
        name: name,
        date: new Date(eventDate),
        address: eventAddress,
        city: eventCity,
        country: eventCountry.label,
        topic: topic,
        thumbnail: thumbnailUpload,
        id: 0,
      })

      setIsLoading(false)
      setName("")
      setEventDate(null)
      setEventAddress("")
      setEventCity("")
      setEventCountry({})
      setTopic("")
      setEventThumbnail("")
      setEventThumbnailPath("")
      setIsLoading(false)
      setEventEditedId(null)
    })
  }

  const [createEventActive, setCreateEventActive] = useState(false)

  // for focusing
  const [nameFocus, setNameFocus] = useState(false)
  const [eventDateFocus, setEventDateFocus] = useState(false)
  const [eventAddressFocus, setEventAddressFocus] = useState(false)
  const [eventCityFocus, setEventCityFocus] = useState(false)
  const [eventCountryFocus, setEventCountryFocus] = useState(false)
  const [topicFocus, setTopicFocus] = useState(false)

  // for values
  const [name, setName] = useState("")
  const [eventDate, setEventDate] = useState("")
  const [eventDates, setEventDates] = useState("")
  const [eventAddress, setEventAddress] = useState("")
  const [eventCity, setEventCity] = useState("")
  const [eventCountry, setEventCountry] = useState({})
  const [topic, setTopic] = useState("")
  const [eventThumbnail, setEventThumbnail] = useState(null)
  const [eventThumbnailPath, setEventThumbnailPath] = useState(null)

  const [isLoading, setIsLoading] = useState(false)

  const [allEvents, setAllEvents] = useState([])
  const [editedEventId, setEventEditedId] = useState(null)

  // useState to get the event that will be deleted
  const [deletedEvent, setDeletedEvent] = useState(null)

  // the countries
  const [countries, setCountries] = useState(null)

  const tabel = country.map(type => {
    return { value: type.name, label: type.name }
  })
  useCollection("event", ["added_by", "==", user.uid]).then(events => {
    if (events) {
      setAllEvents(events)
    }
  })

  useEffect(() => {
    let countriesArray = []
    getDocs(collection(db, "countries")).then(docs => {
      docs.forEach(doc => {
        countriesArray.push(doc.data())
      })
    })
    setCountries(countriesArray)
  }, [])

  return (
    <RightContent>
      {isLoading && <Loader />}
      {!createEventActive && (
        <EventContainer>
          {deletedEvent && (
            <div className="confirm-delete">
              <div className="confirm-delete-content">
                <h5>
                  {arabic == false
                    ? "Are you sure you want delete this event?"
                    : "هل أنت متأكد أنك تريد حذف هذا الحدث؟"}
                </h5>
                <div className="delete-buttons-container">
                  <button
                    className="cancel"
                    onClick={() => setDeletedEvent(null)}
                  >
                    {arabic == false ? "CANCEL" : "إلغاء"}
                  </button>
                  <button className="delete" onClick={deleteEvent}>
                    {arabic == false ? "DELETE" : "حذف"}
                  </button>
                </div>
              </div>
            </div>
          )}
          <h3 className={arabic == false ? "" : "text-end"}>
            {arabic == false ? "My Events" : "أحداثي"}
          </h3>
          <p className={arabic == false ? "subheading" : "subheading text-end"}>
            {arabic == false
              ? "Please provide us with event detailed information"
              : "يرجى تزويدنا بمعلومات مفصلة عن الحدث"}
          </p>
          <div className="add-event">
            <button onClick={() => handleNewEventPressed()}>
              {arabic == false ? "ADD AN EVENT" : "أضف حدثًا"}
            </button>
          </div>
          <div className="events-container">
            {allEvents &&
              allEvents.map(event => {
                const data = event.data
                return (
                  <div className="event" key={event.id}>
                    <div className="content">
                      <img
                        src={event.data.thumbnail.url}
                        className="event-img"
                        alt="event"
                      />
                      <div className="text">
                        <h4>{data.name}</h4>
                        <div className="location">
                          <img src={LocationIcon} alt="location icon" />
                          <span>
                            {data.country.label}, {data.city}
                          </span>
                        </div>
                        <p>{data.topic}</p>
                      </div>
                    </div>
                    <div className="edit">
                      <button onClick={() => setEditingEvent(event)}>
                        {arabic == false ? "EDIT EVENT" : "تعديل الحدث"}
                      </button>
                      <button
                        className="delete"
                        onClick={() => setDeletedEvent(event)}
                      >
                        {arabic == false ? "DELETE EVENT" : "حذف الحدث"}
                      </button>
                    </div>
                  </div>
                )
              })}
          </div>
        </EventContainer>
      )}
      {createEventActive && (
        <AddEventContainer className={arabic == false ? "" : "text-end"}>
          <h3>{arabic == false ? "Create an event" : "أنشئ حدثًا"}</h3>
          <p className="subheading">
            {arabic == false
              ? "You can change submit an event"
              : "يمكنك تغيير تقديم حدث"}
          </p>
          <div
            className={
              arabic == false ? "inputs-container" : "inputs-container text-end"
            }
          >
            <InputContainer isFocus={nameFocus}>
              <span>
                {arabic == false ? "Name of the event*" : "اسم الحدث *"}
              </span>
              <input
                type="text"
                placeholder={
                  arabic == false ? "Enter name of the event" : "أدخل اسم الحدث"
                }
                onFocus={() => setNameFocus(true)}
                onBlur={() => setNameFocus(false)}
                value={name ? name : ""}
                onChange={e => setName(e.target.value)}
              />
            </InputContainer>
            <InputContainer isFocus={eventDateFocus}>
              <span>{arabic == false ? "Event Date*" : "تاريخ الحدث*"}</span>
              <input
                type="datetime-local"
                onFocus={() => setEventDateFocus(true)}
                onBlur={() => setEventDateFocus(false)}
                value={eventDate ? eventDate : null}
                onChange={e => {
                  setEventDate(e.target.value)
                  setEventDates(e.target.value)
                }}
              />
            </InputContainer>
            <InputContainer isFocus={eventAddressFocus}>
              <span>
                {arabic == false ? "Event Address*" : "عنوان الفعالية *"}
              </span>
              <input
                type="text"
                placeholder={
                  arabic == false ? "Enter event address" : "أدخل عنوان الحدث"
                }
                onFocus={() => setEventAddressFocus(true)}
                onBlur={() => setEventAddressFocus(false)}
                value={eventAddress ? eventAddress : ""}
                onChange={e => setEventAddress(e.target.value)}
              />
            </InputContainer>
            <InputContainer isFocus={eventCityFocus}>
              <span>{arabic == false ? "Event City*" : "مدينة الحدث *"}</span>
              <input
                type="text"
                placeholder={
                  arabic == false ? "Enter event city" : "أدخل مدينة الحدث"
                }
                onFocus={() => setEventCityFocus(true)}
                onBlur={() => setEventCityFocus(false)}
                value={eventCity ? eventCity : ""}
                onChange={e => setEventCity(e.target.value)}
              />
            </InputContainer>
            <SelectContainer isFocus={eventCountryFocus}>
              <div
                className={arabic == false ? "content" : "content text-start"}
              >
                <span>
                  {arabic == false ? "Event Country*" : "بلد الفعالية *"}
                </span>
                <Select
                  options={tabel}
                  isSearchable={false}
                  isClearable={false}
                  value={
                    Object.keys(eventCountry).length !== 0 ? eventCountry : null
                  }
                  placeholder={
                    arabic == false ? "Select Country" : "حدد الدولة"
                  }
                  id="property_status"
                  styles={selectStyles}
                  onFocus={() => setEventCountryFocus(true)}
                  onBlur={() => setEventCountryFocus(false)}
                  onChange={e =>
                    setEventCountry({ value: e.value, label: e.label })
                  }
                />
              </div>
              <div className="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.564"
                  height="7.818"
                  viewBox="0 0 13.564 7.818"
                >
                  <path
                    id="XMLID_225_"
                    d="M12.334,75.166a.568.568,0,0,0-.8,0l-5.28,5.28L.97,75.166a.568.568,0,0,0-.8.8l5.682,5.682a.568.568,0,0,0,.8,0l5.682-5.682A.568.568,0,0,0,12.334,75.166Z"
                    transform="translate(0.564 -74.5)"
                    fill="#477b11"
                    stroke="#477b11"
                    strokeWidth="1"
                  />
                </svg>
              </div>
            </SelectContainer>
            <TextareaContainer isFocus={topicFocus}>
              <span>{arabic == false ? "Topic*" : "عنوان*"}</span>
              <textarea
                placeholder={
                  arabic == false ? "Write event topic" : "كتابة موضوع الحدث"
                }
                onChange={e => setTopic(e.target.value)}
                value={topic ? topic : ""}
                onFocus={() => setTopicFocus(true)}
                onBlur={() => setTopicFocus(false)}
                rows={10}
              ></textarea>
            </TextareaContainer>
            <p className="upload-text">
              {arabic == false ? "Upload Thumbnail" : "تحميل الصورة المصغرة"}
            </p>
            <FileUploader
              multiple={false}
              handleChange={uploadThumbnail}
              classes="dropPhotos"
              name="file"
              types={["JPG", "PNG", "GIF"]}
            />
            <Gallery imagePath={eventThumbnail}>
              {eventThumbnail && (
                <div className="thumbnail-container" onClick={deleteThumbnail}>
                  <div className="thumbnail-overlay">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.531"
                      height="7.619"
                      viewBox="0 0 6.531 7.619"
                    >
                      <path
                        id="Path_185"
                        data-name="Path 185"
                        d="M6.407,38.707a.544.544,0,1,1-.836.7l-2.3-2.778-2.3,2.763a.544.544,0,1,1-.836-.7l2.43-2.917-2.431-2.9a.544.544,0,0,1,.836-.7l2.3,2.763,2.3-2.763a.544.544,0,1,1,.836.7L3.977,35.79Z"
                        transform="translate(-0.003 -31.981)"
                      />
                    </svg>
                  </div>
                  <img
                    src={
                      // check if is blob by using .name
                      eventThumbnail.name
                        ? URL.createObjectURL(eventThumbnail)
                        : eventThumbnail
                    }
                  />
                </div>
              )}
            </Gallery>
          </div>
          <div className="buttons-container">
            <button
              className="back"
              onClick={() => setCreateEventActive(false)}
            >
              {arabic == false ? "BACK" : "الى الخلف"}
            </button>
            {(name &&
              eventDate &&
              eventAddress &&
              eventCity &&
              eventCountry &&
              topic &&
              eventThumbnail && (
                <button
                  className="create"
                  onClick={editedEventId ? editEvent : addEvent}
                >
                  {editedEventId ? "EDIT EVENT" : "CREATE EVENT"}
                </button>
              )) || (
              <button className="create disabled" disabled>
                {editedEventId ? "EDIT EVENT" : "CREATE EVENT"}
              </button>
            )}
          </div>
        </AddEventContainer>
      )}
    </RightContent>
  )
}

export default Events
