import React, { useState } from "react"
import { useCollection } from "../../hooks/useCollection"
import { RightContent, NotificationsContainer } from "./Dashboard.styles"
import moment from "moment"
import { sendEmailVerification } from "firebase/auth"

const Notifications = ({ user, isVerified, arabic }) => {
  const userId = user.uid
  const [notifications, setNotifications] = useState([])

  useCollection("notification", ["userId", "==", userId]).then(docs => {
    setNotifications(docs)
  })

  // resend verification email if requested
  const resendVerification = () => {
    sendEmailVerification(user)
  }

  return (
    <RightContent>
      <h3 className={arabic == false ? "" : "text-end"}>
        {arabic == false ? "Notifications" : "إشعارات"}
      </h3>
      <p className={arabic == false ? "subheading" : "subheading text-end"}>
        {arabic == false
          ? "You can view and manage your notifications"
          : "يمكنك عرض وإدارة الإخطارات الخاصة بك"}
      </p>
      <NotificationsContainer>
        {notifications.length !== 0 &&
          notifications.map(notification => {
            if (isVerified && notification.data.type === "verify_email") {
              return <div key={notification.id}></div>
            } else {
              return (
                <div className="notification" key={notification.id}>
                  <div className="left-side">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                    >
                      <g
                        id="Group_461"
                        data-name="Group 461"
                        transform="translate(-38 -167)"
                      >
                        <circle
                          id="Ellipse_27"
                          data-name="Ellipse 27"
                          cx="9"
                          cy="9"
                          r="9"
                          transform="translate(38 168)"
                          fill="#cba876"
                        />
                        <text
                          id="_"
                          data-name="!"
                          transform="translate(45 182)"
                          fill="#fff"
                          font-size="14"
                          font-family="SegoeUI, Segoe UI"
                        >
                          <tspan x="0" y="0">
                            !
                          </tspan>
                        </text>
                      </g>
                    </svg>
                    <div className="text">
                      <h6>
                        {}
                        {arabic == false
                          ? notification.data.subject
                          : "قم بتأكيد بريدك الألكتروني"}
                      </h6>
                      <span>
                        {arabic == false
                          ? moment(
                              notification.data.timestamp.toDate()
                            ).fromNow()
                          : "قبل شهر"}
                      </span>
                      <p>
                        {arabic == false
                          ? notification.data.body
                          : "أهلا بك ، لم يتم التحقق من حسابك بعد. تم إرسال بريد إلكتروني للتحقق. من فضلك تفقد بريدك الالكتروني"}
                      </p>
                    </div>
                  </div>
                  <div className="right-side">
                    <button className="delete">
                      {arabic == false ? "DELETE" : "حذف"}
                    </button>
                    {notification.data.type === "verify_email" && (
                      <button
                        className="read-more"
                        onClick={resendVerification}
                      >
                        {arabic == false
                          ? "RESEND VERIFICATION"
                          : "إعادة التحقق"}
                      </button>
                    )}
                  </div>
                </div>
              )
            }
          })}
      </NotificationsContainer>
    </RightContent>
  )
}

export default Notifications
