import React, { useEffect, useState } from "react"

import { PhotoContainer, RightContent } from "./Dashboard.styles"
import UserPhoto from "../../assets/images/user.jpg"
import { InputContainer } from "../GlobalComponents/GlobalComponents.styles"
import { useDocument } from "../../hooks/useDocument"
import { storage, db } from "../../firebase/config"
import { doc } from "firebase/firestore"
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage"
import { updateProfile } from "firebase/auth"
import Loader from "../Loader"
import { updateDoc } from "firebase/firestore"

const Personal = ({ user, arabic }) => {
  const [photo, setPhoto] = useState(UserPhoto)

  // getting the user data from firebase
  useDocument("users", user.uid).then(data => {
    if (data.data) {
      setUserData(data.data)
    }
  })

  // for focusing
  const [firstNameFocus, setFirstNameFocus] = useState(false)
  const [lastNameFocus, setLastNameFocus] = useState(false)

  // for values
  const [userData, setUserData] = useState({})
  const [firstName, setFirstName] = useState("") // to be brought from firebase later
  const [lastName, setLastName] = useState("") // to be brought from firebase later
  const [phoneNumber, setPhoneNumber] = useState("")
  const [position, setPosition] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handlePhotoUpload = e => {
    const acceptedImages = ["image/jpg", "image/jpeg", "image/png"]
    const image = e.target.files[0]
    if (!acceptedImages.includes(image.type)) {
      alert("Please enter a valid type")
      return
    }

    if (image.size > 200000) {
      alert("Image size is too big")
      return
    }

    setIsLoading(true)
    const referene = ref(storage, `images/users/${user.uid}/${image.name}`)

    const upload = () => {
      uploadBytes(referene, image)
        .then(() => {
          getDownloadURL(referene)
            .then(url => {
              setPhoto(url)
              updateProfile(user, {
                photoURL: url,
              })
                .then(() => {
                  setIsLoading(false)
                  updateDoc(ref(doc(db, "users", user.uid)), {
                    profile_image: {
                      url: url,
                      path: `images/users/${user.uid}/${image.name}`,
                    },
                  })
                })
                .catch(() => {
                  alert("There has been a problem while updating your profile")
                  setIsLoading(false)
                })
            })
            .catch(() => {
              alert("There has been a problem while uploding image")
              setIsLoading(false)
            })
        })
        .catch(() => {
          alert("There has been a problem while uploading image")
          setIsLoading(false)
        })
    }

    if (user.photoURL) {
      deleteObject(ref(storage, userData.profile_image.path)).then(() => {
        upload()
      })
    } else {
      upload()
    }
  }

  // deleting the profile image
  const handleDelete = () => {
    setIsLoading(true)
    deleteObject(ref(storage, userData.profile_image.path))
      .then(() => {
        updateProfile(user, {
          photoURL: "",
        })
          .then(() => {
            updateDoc(ref(doc(db, "users", user.uid)), {
              profile_image: {},
            })
              .then(() => {
                setPhoto(UserPhoto)
                setIsLoading(false)
              })
              .catch(error => {
                setIsLoading(false)
                alert("There was an error when updating your profile")
              })
          })
          .catch(error => {
            setIsLoading(false)
            alert("There was an error when updating your profile")
          })
      })
      .catch(error => {
        setIsLoading(false)
        alert("There was an error when trying to deleting the image")
      })
  }
  const handleEdit = () => {
    const document = doc(db, "users", user.uid)
    updateDoc(document, {
      first_name: firstName,
      last_name: lastName,
    })
  }

  // update the name when data is fetched
  useEffect(() => {
    setFirstName(userData.first_name)
    setLastName(userData.last_name)
    setPhoneNumber(userData.phone_number)
    setPosition(userData.type)
    setEmailAddress(userData.email)
    if (user.photoURL) {
      setPhoto(user.photoURL)
    }
  }, [userData])

  return (
    <RightContent>
      {isLoading && <Loader />}
      <h3 className={arabic == false ? "" : "text-end"}>
        {arabic == false ? "Personal Information" : "معلومات شخصية"}{" "}
      </h3>
      <p className={arabic == false ? "subheading" : "subheading text-end"}>
        {arabic == false
          ? "You are eligible to manage your personal information"
          : "أنت مؤهل لإدارة معلوماتك الشخصية"}
      </p>
      <PhotoContainer>
        <img src={photo} alt="thubmnail image" />
        <div className="replace">
          <label htmlFor="replace">
            {arabic == false ? "REPLACE" : "يحل محل"}
          </label>
          <input
            type="file"
            id="replace"
            accept=".png,.jpeg,.jpg"
            onChange={e => handlePhotoUpload(e)}
          />
        </div>
        <div className="remove">
          {user.photoURL && (
            <button onClick={handleDelete}>
              {arabic == false ? "REMOVE" : "إزالة"}
            </button>
          )}
          {!user.photoURL && (
            <button className="disabled" disabled>
              {arabic == false ? "REMOVE" : "إزالة"}
            </button>
          )}
        </div>
      </PhotoContainer>
      <InputContainer isFocus={firstNameFocus}>
        <span>{arabic == false ? "First Name" : "الاسم الاول"}</span>
        <input
          type="text"
          onFocus={() => setFirstNameFocus(true)}
          onBlur={() => setFirstNameFocus(false)}
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
      </InputContainer>
      <InputContainer isFocus={lastNameFocus}>
        <span>{arabic == false ? "Last Name" : "الكنية"}</span>
        <input
          type="text"
          onFocus={() => setLastNameFocus(true)}
          onBlur={() => setLastNameFocus(false)}
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />
      </InputContainer>
      <InputContainer isFocus={false}>
        <span>{arabic == false ? "Phone Number" : "رقم الهاتف"}</span>
        <input type="text" disabled value={phoneNumber} />
      </InputContainer>
      <InputContainer isFocus={false}>
        <span>
          {arabic == false ? "Email Address" : "عنوان البريد الالكترونى"}
        </span>
        <input type="text" disabled value={emailAddress} />
      </InputContainer>
      <InputContainer isFocus={false}>
        <span>{arabic == false ? "Agent or Seller" : "وكيل أو بائع"}</span>
        <input type="text" disabled value={position} />
      </InputContainer>
      <div className="button-container">
        <button className="save" onClick={handleEdit}>
          {arabic == false ? "SAVE" : "حفظ"}
        </button>
      </div>
    </RightContent>
  )
}

export default Personal
