import React from 'react'

const EventsIcon = ({isActive}) => {
    if(isActive){
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.274"
                height="16.269"
                viewBox="0 0 17.274 16.269"
                >
                <g
                    fill="#1d8cc3"
                    stroke="#1d8cc3"
                    strokeWidth="0.2"
                    data-name="calendar (2)"
                    transform="translate(.1 .1)"
                >
                    <path
                    d="M17.074 15.316V5.775a.251.251 0 10-.5 0v9.541a.251.251 0 01-.251.251H.753a.251.251 0 01-.253-.251V5.775a.251.251 0 10-.5 0v9.541a.754.754 0 00.753.753H16.32a.754.754 0 00.754-.753z"
                    data-name="Path 158"
                    ></path>
                    <path
                    d="M2.26 11.55a.251.251 0 000 .5h.753v1.758a.251.251 0 00.5 0v-1.756h3.015v1.758a.251.251 0 00.5 0v-1.758h3.013v1.758a.251.251 0 00.5 0v-1.758h3.013v1.758a.251.251 0 00.5 0v-1.758h.753a.251.251 0 100-.5h-.753V9.039h.753a.251.251 0 100-.5h-.753V6.277a.251.251 0 10-.5 0v2.26h-3.008v-2.26a.251.251 0 10-.5 0v2.26H7.03v-2.26a.251.251 0 10-.5 0v2.26H3.515v-2.26a.251.251 0 10-.5 0v2.26H2.26a.251.251 0 100 .5h.753v2.511zm11.3-2.511v2.511h-3.014V9.039zm-3.515 0v2.511H7.03V9.039zm-6.528 0h3.011v2.511H3.515zm10.795-7.535h2.134c.044 0 .126 0 .126.251v2.26H.5V1.757a.251.251 0 01.253-.253h2.009a.251.251 0 000-.5H.753A.754.754 0 000 1.757v2.511a.251.251 0 00.251.251h16.572a.251.251 0 00.251-.251V1.757c0-.555-.324-.753-.628-.753h-2.134a.251.251 0 100 .5z"
                    data-name="Path 159"
                    ></path>
                    <path
                    d="M11.298 1.507a.251.251 0 100-.5H5.775a.251.251 0 000 .5zm-6.781.753V.251a.251.251 0 00-.5 0V2.26a.251.251 0 00.5 0zm8.286.251a.251.251 0 00.251-.251V.251a.251.251 0 00-.5 0V2.26a.251.251 0 00.251.251z"
                    data-name="Path 160"
                    ></path>
                </g>
                </svg>
        )
    } else {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.274"
                height="16.269"
                viewBox="0 0 17.274 16.269"
                >
                <g
                    stroke="#000"
                    strokeWidth="0.2"
                    data-name="calendar (2)"
                    transform="translate(.1 .1)"
                >
                    <path
                    d="M17.074 15.316V5.775a.251.251 0 10-.5 0v9.541a.251.251 0 01-.251.251H.753a.251.251 0 01-.253-.251V5.775a.251.251 0 10-.5 0v9.541a.754.754 0 00.753.753H16.32a.754.754 0 00.754-.753z"
                    data-name="Path 158"
                    ></path>
                    <path
                    d="M2.26 11.55a.251.251 0 000 .5h.753v1.758a.251.251 0 00.5 0v-1.756h3.015v1.758a.251.251 0 00.5 0v-1.758h3.013v1.758a.251.251 0 00.5 0v-1.758h3.013v1.758a.251.251 0 00.5 0v-1.758h.753a.251.251 0 100-.5h-.753V9.039h.753a.251.251 0 100-.5h-.753V6.277a.251.251 0 10-.5 0v2.26h-3.008v-2.26a.251.251 0 10-.5 0v2.26H7.03v-2.26a.251.251 0 10-.5 0v2.26H3.515v-2.26a.251.251 0 10-.5 0v2.26H2.26a.251.251 0 100 .5h.753v2.511zm11.3-2.511v2.511h-3.014V9.039zm-3.515 0v2.511H7.03V9.039zm-6.528 0h3.011v2.511H3.515zm10.795-7.535h2.134c.044 0 .126 0 .126.251v2.26H.5V1.757a.251.251 0 01.253-.253h2.009a.251.251 0 000-.5H.753A.754.754 0 000 1.757v2.511a.251.251 0 00.251.251h16.572a.251.251 0 00.251-.251V1.757c0-.555-.324-.753-.628-.753h-2.134a.251.251 0 100 .5z"
                    data-name="Path 159"
                    ></path>
                    <path
                    d="M11.298 1.507a.251.251 0 100-.5H5.775a.251.251 0 000 .5zm-6.781.753V.251a.251.251 0 00-.5 0V2.26a.251.251 0 00.5 0zm8.286.251a.251.251 0 00.251-.251V.251a.251.251 0 00-.5 0V2.26a.251.251 0 00.251.251z"
                    data-name="Path 160"
                    ></path>
                </g>
            </svg>
        )
    }
}

export default EventsIcon