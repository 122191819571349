import React, { useState, useEffect } from "react"
import {
  InboxContainer,
  RightContent,
  ToBeDeletedPopup,
} from "./Dashboard.styles"

import userImage from "../../assets/images/user.jpg"
import { useCollection } from "../../hooks/useCollection"

import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore"
import { db } from "../../firebase/config"
import { getCurrentTime } from "../../helpers/timeManipulation"
import Chat from "./chat"

import { ChatContext } from "./chatContext"
import { displayMessageTime } from "../../helpers/timeManipulation"

const Inbox = ({ user }) => {
  const [isReply, setIsReply] = useState(false)
  const [messagesShowing, setMessagesShowing] = useState(null)
  const [chat, setChat] = useState(null)
  const [guestUser, setGuestUser] = useState(null)
  const [toBeDeleted, setToBeDeleted] = useState(null)

  const [inboxes, setInboxes] = useState([])
  const [replyValue, setReplyValue] = useState("")

  const handleMessageShowing = message => {
    setMessagesShowing(message)
    const guestId = message.data.users.filter(e => e !== user.uid)
    getDoc(doc(db, "users", guestId[0]))
      .then(doc => {
        if (doc.data()) {
          setGuestUser(doc.data())
        }
      })
      .then(() => {
        setIsReply(true)
      })
  }

  // real time updates for messages
  useEffect(() => {
    if (messagesShowing) {
      onSnapshot(doc(db, "inbox", messagesShowing.id), doc => {
        if (doc.data()) {
          setChat(doc.data().messages)
        }
      })
    }
  }, [messagesShowing])

  // handling the delete of the chat
  const handleDeleteChat = () => {
    const documentRef = doc(db, "inbox", toBeDeleted)
    updateDoc(documentRef, {
      activeUsers: arrayRemove(user.uid),
    }).then(() => {
      setToBeDeleted(null)
      window.location = "/dashboard"
    })
  }

  // handling the back button press
  const handleBackButton = () => {
    setGuestUser(null)
    setIsReply(false)
    setChat(null)
    setReplyValue("")
  }

  // when the user submits a message
  const handleSubmitMessage = e => {
    e.preventDefault()

    const messageRef = doc(db, "inbox", messagesShowing.id)
    const datetime = getCurrentTime()
    updateDoc(messageRef, {
      messages: arrayUnion({
        content: replyValue,
        timestamp: datetime,
        userId: user.uid,
      }),
    }).then(() => {
      setReplyValue("")
    })
  }

  useCollection("inbox", ["activeUsers", "array-contains", user.uid]).then(
    messages => {
      if (messages) {
        setInboxes(messages)
      }
    }
  )

  return (
    // <ChatContext.Provider value={{ handleMessageShowing, setToBeDeleted }}>
    //   {toBeDeleted && (
    //     <ToBeDeletedPopup>
    //       <div className="content">
    //         <h3>Are you sure you want to delete this chat?</h3>
    //         <div className="buttons">
    //           <button className="cancel" onClick={() => setToBeDeleted(null)}>
    //             Cancel
    //           </button>
    //           <button className="delete" onClick={handleDeleteChat}>
    //             Delete
    //           </button>
    //         </div>
    //       </div>
    //     </ToBeDeletedPopup>
    //   )}
    //   <RightContent>
    //     <h3>Inbox</h3>
    //     <p className="subheading">You can view and manage your messages</p>
    //     {!isReply && (
    //       <InboxContainer>
    //         {inboxes &&
    //           inboxes.map(inbox => {
    //             let eventName
    //             if (inbox.data.eventId) {
    //               getDoc(doc(db, "event", inbox.data.eventId)).then(event => {
    //                 eventName = event.data().name
    //               })
    //               return <Chat key={inbox.id} user={user} inbox={inbox} />
    //             }
    //           })}
    //       </InboxContainer>
    //     )}
    //     {isReply && (
    //       <InboxContainer>
    //         {chat &&
    //           chat.map(message => {
    //             const date = displayMessageTime(message.timestamp)
    //             return (
    //               <div
    //                 className={`message ${
    //                   message.userId === user.uid ? "sent" : ""
    //                 }`}
    //                 key={message.timestamp}
    //               >
    //                 <div className="content">
    //                   <div className="img-container">
    //                     <img
    //                       src={
    //                         message.userId !== user.uid
    //                           ? guestUser.profile_image.url
    //                             ? guestUser.profile_image.url
    //                             : userImage
    //                           : user.photoURL
    //                           ? user.photoURL
    //                           : userImage
    //                       }
    //                       alt="user Image"
    //                     />
    //                     <p className="user-name">
    //                       {message.userId !== user.uid
    //                         ? guestUser.first_name + " " + guestUser.last_name
    //                         : "You"}
    //                     </p>
    //                   </div>

    //                   <span>{date}</span>
    //                   <p>{message.content}</p>
    //                 </div>
    //               </div>
    //             )
    //           })}
    //         <form>
    //           <div className="reply-box">
    //             <div className="content">
    //               <img src={userImage} alt="user Image" />
    //               <span>Reply</span>
    //               <textarea
    //                 rows={10}
    //                 placeholder="Enter Message"
    //                 value={replyValue}
    //                 onChange={e => setReplyValue(e.target.value)}
    //               ></textarea>
    //             </div>
    //           </div>
    //           <div className="button-container">
    //             <button className="back" onClick={handleBackButton}>
    //               GO BACK
    //             </button>
    //             <button type="submit" onClick={handleSubmitMessage}>
    //               SEND MESSAGE
    //             </button>
    //           </div>
    //         </form>
    //       </InboxContainer>
    //     )}
    //   </RightContent>
    // </ChatContext.Provider>
    <>
    </>
  )
}

export default Inbox
