import React from 'react'

const ContactIcon = ({isActive}) => {
    if(isActive){
        return(
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                >
                <g fill="#1d8cc3" transform="translate(-8 -8)">
                    <path
                        d="M13.409 8A5.406 5.406 0 008 13.409v6.182A5.406 5.406 0 0013.409 25h6.182A5.406 5.406 0 0025 19.591v-6.182A5.406 5.406 0 0019.591 8zm0 .773h5.8v15.454h-5.8a4.618 4.618 0 01-4.636-4.636v-6.182a4.618 4.618 0 014.636-4.636zm6.568.02a4.613 4.613 0 014.25 4.617v.39h-4.25v-5zm0 5.776h4.25v3.864h-4.25zm0 4.636h4.25v.386a4.613 4.613 0 01-4.25 4.617z"
                        data-name="Path 154"
                    ></path>
                    <path
                        d="M19.091 21a2.315 2.315 0 00-1.639 3.953A3.09 3.09 0 0016 27.568a.386.386 0 00.773 0 2.308 2.308 0 011.4-2.123 2.3 2.3 0 001.84 0 2.308 2.308 0 011.4 2.123.386.386 0 00.773 0 3.09 3.09 0 00-1.452-2.615A2.315 2.315 0 0019.091 21zm0 .773a1.545 1.545 0 11-1.545 1.545 1.54 1.54 0 011.545-1.545z"
                        data-name="Path 155"
                        transform="translate(-4.909 -7.977)"
                    ></path>
                </g>
            </svg>
        )
    } else{
        return (
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            >
            <g transform="translate(-8 -8)">
                <path
                d="M13.409 8A5.406 5.406 0 008 13.409v6.182A5.406 5.406 0 0013.409 25h6.182A5.406 5.406 0 0025 19.591v-6.182A5.406 5.406 0 0019.591 8zm0 .773h5.8v15.454h-5.8a4.618 4.618 0 01-4.636-4.636v-6.182a4.618 4.618 0 014.636-4.636zm6.568.02a4.613 4.613 0 014.25 4.617v.39h-4.25v-5zm0 5.776h4.25v3.864h-4.25zm0 4.636h4.25v.386a4.613 4.613 0 01-4.25 4.617z"
                data-name="Path 154"
                ></path>
                <path
                d="M19.091 21a2.315 2.315 0 00-1.639 3.953A3.09 3.09 0 0016 27.568a.386.386 0 00.773 0 2.308 2.308 0 011.4-2.123 2.3 2.3 0 001.84 0 2.308 2.308 0 011.4 2.123.386.386 0 00.773 0 3.09 3.09 0 00-1.452-2.615A2.315 2.315 0 0019.091 21zm0 .773a1.545 1.545 0 11-1.545 1.545 1.54 1.54 0 011.545-1.545z"
                data-name="Path 155"
                transform="translate(-4.909 -7.977)"
                ></path>
            </g>
            </svg>
        )
    }
}

export default ContactIcon