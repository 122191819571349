import React, { useContext } from "react"

import DasboardComponent from "../components/Dashboard"
import DashboardHero from "../components/Hero/DashboardHero"

import Seo from "../components/seo"

import { useAuthContext } from "../hooks/useAuthContext"
import MustSignIn from "../components/MustSignIn"
import { graphql } from "gatsby"
import { StateContext } from "../helpers/StateProvider"


const Dashboard = () => {
  const { user, authIsReady } = useAuthContext()

  const { language, setLanguage } = useContext(StateContext)
  return (
    
    <>
    
      {authIsReady && (
        <>
          <Seo title="Dashboard" />
          {!user && <MustSignIn />}
          {user && (
            <>
              <DashboardHero user={user} arabic={language} />
              <DasboardComponent
                user={user}
                arabic={language}
                arabic_action={setLanguage}
              />
            </>
          )}
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`

export default Dashboard
