import React, { useState, useRef } from "react"
import { ContactContainer, RightContent } from "./Dashboard.styles"
import {
  InputContainer,
  TextareaContainer,
} from "../GlobalComponents/GlobalComponents.styles"
import * as Yup from "yup"
import { useFormik } from "formik"
import { db } from "../../firebase/config"
import { addDoc, collection } from "firebase/firestore"
import emailjs from "emailjs-com"

const Contact = ({ user, arabic }) => {
  // for focusing
  const [subjectFocus, setSubjectFocus] = useState(false)
  const [messageFocus, setMessageFocus] = useState(false)
  const colRef = collection(db, "contact")

  // for values
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")

  // for the message status
  const [messageStatus, setMessageStatus] = useState("")
  const form = useRef()
  const handleMessage = () => {
    setSubject("")
    setMessage("")
    setMessageStatus("Sent")
  }
  const validation = useFormik({
    initialValues: {
      subject: "",
      message: "",
    },

    validationSchema: Yup.object({
      subject: Yup.string().required("This Field is required"),
      message: Yup.string().required("This Field is required"),
    }),

    onSubmit: values => {
      addDoc(colRef, {
        message: "subject:" + values.subject + " message:" + values.message,
        companyName: "",
        emailAddress: user.email,
        firstName: user.displayName,
        lastName: user.displayName,
      })
      setSubject("")
      setMessage("")
      setMessageStatus("Sent")
      emailjs.send(
        "opd_mailing_service",
        "template_sdxewsf",
        {
          from_name: "",
          to_name: "mr Muhammad Itani",
          message: values.message,
          company: "",
          email: "",
          number: "",
        },
        "g1dJssA6i85wnSF7J"
      )
    },
  })
  return (
    <RightContent>
      <h3 className={arabic == false ? "" : "text-end"}>
        {arabic == false ? "Contact" : "اتصال"}
      </h3>
      <p className={arabic == false ? "subheading" : "subheading text-end"}>
        {arabic == false ? "Need Help?" : "تحتاج مساعدة؟"}
      </p>
      <ContactContainer messageStatus={messageStatus}>
        <form
          ref={form}
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
          }}
        >
          <InputContainer isFocus={subjectFocus}>
            <span>{arabic == false ? "Subject*" : "موضوعات*"}</span>
            <input
              type="text"
              name="subject"
              placeholder={
                arabic == false
                  ? "Please Enter subject"
                  : "الرجاء إدخال الموضوع"
              }
              onChange={validation.handleChange}
              value={validation.values.subject}
              onBlur={validation.handleBlur}
            />
            {validation.touched.subject && validation.errors.subject ? (
              <p className="error pt-2">{validation.errors.subject} </p>
            ) : null}
          </InputContainer>
          <TextareaContainer isFocus={messageFocus}>
            <span>{arabic == false ? "Message*" : "رسالة*"}</span>
            <textarea
              placeholder={arabic == false ? "Write Message" : "اكتب رسالة"}
              rows={10}
              name="message"
              onChange={validation.handleChange}
              value={validation.values.message}
              onBlur={validation.handleBlur}
            />
          </TextareaContainer>
          {validation.touched.message && validation.errors.message ? (
            <p className="error pt-2">{validation.errors.message} </p>
          ) : null}
          <div className="submit">
            <input
              type="submit"
              value={arabic == false ? "SUBMIT MESSAGE" : "تقديم رسالة"}
            />
          </div>
          {messageStatus && (
            <div className="notification-container">
              {arabic == false
                ? "Your message has been sent"
                : "تم ارسال رسالتك"}
            </div>
          )}
        </form>
      </ContactContainer>
    </RightContent>
  )
}

export default Contact
