import React, { useState } from "react"
import { useDocument } from "../../hooks/useDocument"
import { Link } from "gatsby"

const LikedProperty = ({ propertyId, arabic }) => {
  const [data, setData] = useState()
  useDocument("property", propertyId).then(document => {
    if (document) {
      setData(document.data)
    }
  })

  return (
    <>
      {data && (
        <div className="listing">
          <div className="info">
            <img
              src={data.featured_image.url}
              className="featured-img"
              alt="Modern villa"
              width={250}
            />
            <div className="text">
              <h5>
                <Link to={`/property/${propertyId}`}>{data.title}</Link>
              </h5>
              <p className="location">
                {data.address}, {data.country.label}
              </p>
              <div className="amenities">
                {data.num_bedrooms} {arabic == false ? "Beds" : "سرير"} |{" "}
                {data.num_bathrooms} {arabic == false ? "Bath" : "حمام"} |{" "}
                {data.area} {arabic == false ? "m" : " مترًا مربعًا"}
                <sup>2</sup>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LikedProperty
