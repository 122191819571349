import React from 'react'

const ParametersIcons = ({isActive}) => {
    if(isActive){
        return(
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.774"
            height="17.77"
            viewBox="0 0 17.774 17.77"
            >
            <g
                fill="#1d8cc3"
                stroke="#1d8cc3"
                strokeWidth="0.2"
                data-name="setting (1)"
                transform="translate(-11.11 -11.115)"
            >
                <path
                d="M28.528 21.51a.369.369 0 00.256-.352v-2.3a.369.369 0 00-.256-.352l-2.174-.7a7.659 7.659 0 00-.3-.722l1.046-2.04a.369.369 0 00-.067-.429L25.4 12.982a.369.369 0 00-.429-.068l-2.039 1.04a6.094 6.094 0 00-.731-.305l-.7-2.177a.37.37 0 00-.352-.257h-2.3a.369.369 0 00-.351.256l-.7 2.176a6.143 6.143 0 00-.731.3l-2.027-1.042a.37.37 0 00-.43.068l-1.633 1.636a.369.369 0 00-.067.429l1.033 2.026a5.96 5.96 0 00-.3.73l-2.168.7a.369.369 0 00-.256.351l-.007 2.3a.369.369 0 00.255.352l2.177.707a7.456 7.456 0 00.3.722l-1.039 2.033a.369.369 0 00.067.429l1.628 1.637a.367.367 0 00.429.069l2.038-1.038a6.479 6.479 0 00.722.3l.707 2.172a.369.369 0 00.351.255h2.3a.369.369 0 00.351-.255l.708-2.174a7.282 7.282 0 00.72-.3l2.037 1.046a.37.37 0 00.429-.067l1.629-1.633a.369.369 0 00.068-.429l-1.037-2.043a6.262 6.262 0 00.3-.724zm-3.22 1.59l1 1.973-1.248 1.245-1.962-1a.367.367 0 00-.337 0 6.464 6.464 0 01-.962.4.369.369 0 00-.236.237l-.682 2.094h-1.768l-.682-2.094a.369.369 0 00-.24-.238 5.866 5.866 0 01-.956-.4.37.37 0 00-.339 0l-1.965 1-1.246-1.252 1-1.959a.37.37 0 000-.333 6.809 6.809 0 01-.4-.968.37.37 0 00-.237-.238l-2.1-.681.005-1.767 2.094-.675a.37.37 0 00.241-.246 5.189 5.189 0 01.393-.955.369.369 0 000-.342l-1-1.955 1.253-1.256 1.966 1.004a.372.372 0 00.349-.006 5.235 5.235 0 01.958-.4.369.369 0 00.237-.237l.678-2.1h1.766l.671 2.1a.369.369 0 00.242.24 5.292 5.292 0 01.956.4.37.37 0 00.345 0l1.969-1 1.249 1.252-1.008 1.966a.369.369 0 000 .339 6.878 6.878 0 01.4.967.369.369 0 00.237.233l2.093.671v1.766l-2.1.675a.37.37 0 00-.238.238 5.458 5.458 0 01-.4.952.369.369 0 00.004.35z"
                data-name="Path 151"
                ></path>
                <path
                d="M20.461 16.514a3.52 3.52 0 103.028 3.951 3.521 3.521 0 00-3.028-3.951zm2.3 3.853a2.786 2.786 0 01-3.128 2.393 2.781 2.781 0 11.735-5.514 2.785 2.785 0 012.389 3.122z"
                data-name="Path 152"
                ></path>
            </g>
            </svg>
        )
    } else {
        return(
            <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.774"
      height="17.77"
      viewBox="0 0 17.774 17.77"
    >
      <g
        stroke="#000"
        strokeWidth="0.2"
        data-name="setting (1)"
        transform="translate(-11.11 -11.115)"
      >
        <path
          d="M28.528 21.51a.369.369 0 00.256-.352v-2.3a.369.369 0 00-.256-.352l-2.174-.7a7.659 7.659 0 00-.3-.722l1.046-2.04a.369.369 0 00-.067-.429L25.4 12.982a.369.369 0 00-.429-.068l-2.039 1.04a6.094 6.094 0 00-.731-.305l-.7-2.177a.37.37 0 00-.352-.257h-2.3a.369.369 0 00-.351.256l-.7 2.176a6.143 6.143 0 00-.731.3l-2.027-1.042a.37.37 0 00-.43.068l-1.633 1.636a.369.369 0 00-.067.429l1.033 2.026a5.96 5.96 0 00-.3.73l-2.168.7a.369.369 0 00-.256.351l-.007 2.3a.369.369 0 00.255.352l2.177.707a7.456 7.456 0 00.3.722l-1.039 2.033a.369.369 0 00.067.429l1.628 1.637a.367.367 0 00.429.069l2.038-1.038a6.479 6.479 0 00.722.3l.707 2.172a.369.369 0 00.351.255h2.3a.369.369 0 00.351-.255l.708-2.174a7.282 7.282 0 00.72-.3l2.037 1.046a.37.37 0 00.429-.067l1.629-1.633a.369.369 0 00.068-.429l-1.037-2.043a6.262 6.262 0 00.3-.724zm-3.22 1.59l1 1.973-1.248 1.245-1.962-1a.367.367 0 00-.337 0 6.464 6.464 0 01-.962.4.369.369 0 00-.236.237l-.682 2.094h-1.768l-.682-2.094a.369.369 0 00-.24-.238 5.866 5.866 0 01-.956-.4.37.37 0 00-.339 0l-1.965 1-1.246-1.252 1-1.959a.37.37 0 000-.333 6.809 6.809 0 01-.4-.968.37.37 0 00-.237-.238l-2.1-.681.005-1.767 2.094-.675a.37.37 0 00.241-.246 5.189 5.189 0 01.393-.955.369.369 0 000-.342l-1-1.955 1.253-1.256 1.966 1.004a.372.372 0 00.349-.006 5.235 5.235 0 01.958-.4.369.369 0 00.237-.237l.678-2.1h1.766l.671 2.1a.369.369 0 00.242.24 5.292 5.292 0 01.956.4.37.37 0 00.345 0l1.969-1 1.249 1.252-1.008 1.966a.369.369 0 000 .339 6.878 6.878 0 01.4.967.369.369 0 00.237.233l2.093.671v1.766l-2.1.675a.37.37 0 00-.238.238 5.458 5.458 0 01-.4.952.369.369 0 00.004.35z"
          data-name="Path 151"
        ></path>
        <path
          d="M20.461 16.514a3.52 3.52 0 103.028 3.951 3.521 3.521 0 00-3.028-3.951zm2.3 3.853a2.786 2.786 0 01-3.128 2.393 2.781 2.781 0 11.735-5.514 2.785 2.785 0 012.389 3.122z"
          data-name="Path 152"
        ></path>
      </g>
    </svg>
        )
    }
}

export default ParametersIcons