import React from "react"

const LikedPropertiesIcon = ({ isActive }) => {
  if (isActive) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.62"
        height="15.581"
        viewBox="0 0 16.62 15.581"
      >
        <path
          fill="#1d8cc3"
          stroke="#1d8cc3"
          strokeWidth="0"
          d="M8.31 2.855l-.745-.766a3.9 3.9 0 00-6.111 1.082 4.9 4.9 0 00.326 4.555c.956 1.885 2.944 4.144 6.53 6.6 3.586-2.46 5.573-4.718 6.53-6.6a4.9 4.9 0 00.326-4.555 3.9 3.9 0 00-6.111-1.083zm0 12.727C-7.617 5.057 3.406-3.158 8.127 1.187c.062.057.124.116.183.178q.088-.092.183-.177c4.72-4.348 15.744 3.868-.183 14.393z"
          data-name="Path 194"
        ></path>
      </svg>
    )
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.62"
        height="15.581"
        viewBox="0 0 16.62 15.581"
      >
        <path
          stroke="#000"
          strokeWidth="0"
          d="M8.31 2.855l-.745-.766a3.9 3.9 0 00-6.111 1.082 4.9 4.9 0 00.326 4.555c.956 1.885 2.944 4.144 6.53 6.6 3.586-2.46 5.573-4.718 6.53-6.6a4.9 4.9 0 00.326-4.555 3.9 3.9 0 00-6.111-1.083zm0 12.727C-7.617 5.057 3.406-3.158 8.127 1.187c.062.057.124.116.183.178q.088-.092.183-.177c4.72-4.348 15.744 3.868-.183 14.393z"
          data-name="Path 194"
        ></path>
      </svg>
    )
  }
}

export default LikedPropertiesIcon
