import React from 'react'

const ListingsIcon = ({isActive}) => {
    if(isActive){
        return(
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.574"
                height="18.926"
                viewBox="0 0 17.574 18.926"
                >
                <g fill="#1d8cc3" transform="translate(-6 -4)">
                    <path
                    d="M15.463 4H8.028A2.028 2.028 0 006 6.028V20.9a2.028 2.028 0 002.028 2.028h13.518a2.028 2.028 0 002.028-2.028v-7.437a2.028 2.028 0 00-2.028-2.028h-4.055V6.028A2.028 2.028 0 0015.463 4zM6.676 20.9V6.028a1.352 1.352 0 011.352-1.352h7.435a1.352 1.352 0 011.352 1.352V22.25h-2.7v-4.394a.338.338 0 00-.338-.338H9.718a.338.338 0 00-.338.338v4.394H8.028a1.352 1.352 0 01-1.352-1.35zm3.38 1.352v-4.058h3.38v4.056zm11.49-10.141a1.352 1.352 0 011.354 1.352V20.9a1.352 1.352 0 01-1.352 1.352h-4.057V12.111z"
                    data-name="Path 156"
                    ></path>
                    <path
                    d="M12.338 12.028h1.352a.338.338 0 00.338-.338v-1.352A.338.338 0 0013.69 10h-1.352a.338.338 0 00-.338.338v1.352a.338.338 0 00.338.338zm.338-1.352h.676v.676h-.676zm-.338 4.731h1.352a.338.338 0 00.338-.338v-1.351a.338.338 0 00-.338-.338h-1.352a.338.338 0 00-.338.338v1.352a.338.338 0 00.338.337zm.338-1.352h.676v.676h-.676zm1.014 4.731a.338.338 0 00.338-.338V17.1a.338.338 0 00-.338-.338h-1.352A.338.338 0 0012 17.1v1.352a.338.338 0 00.338.338zm-1.014-1.352h.676v.676h-.676zm5.069-5.407H19.1a.338.338 0 00.338-.338v-1.351A.338.338 0 0019.1 10h-1.355a.338.338 0 00-.338.338v1.352a.338.338 0 00.338.338zm.338-1.352h.676v.676h-.676zm-.338 4.731H19.1a.338.338 0 00.338-.338v-1.35a.338.338 0 00-.338-.338h-1.355a.338.338 0 00-.338.338v1.352a.338.338 0 00.338.337zm.338-1.352h.676v.676h-.676zm-.338 4.731H19.1a.338.338 0 00.338-.338V17.1a.338.338 0 00-.338-.338h-1.355a.338.338 0 00-.338.338v1.352a.338.338 0 00.338.335zm.338-1.352h.676v.676h-.676zm5.745 2.028h1.352a.338.338 0 00.338-.338v-1.35a.338.338 0 00-.338-.338h-1.352a.338.338 0 00-.338.338v1.352a.338.338 0 00.338.338zm.338-1.352h.676v.676h-.676zm-.338 4.731h1.352a.338.338 0 00.338-.338v-1.349a.338.338 0 00-.338-.338h-1.352a.338.338 0 00-.338.338V22.5a.338.338 0 00.338.342zm.338-1.352h.676v.676h-.676z"
                    data-name="Path 157"
                    transform="translate(-3.972 -3.972)"
                    ></path>
                </g>
                </svg>
        )
    } else {
        return (
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.574"
            height="18.926"
            viewBox="0 0 17.574 18.926"
            >
            <g transform="translate(-6 -4)">
                <path
                d="M15.463 4H8.028A2.028 2.028 0 006 6.028V20.9a2.028 2.028 0 002.028 2.028h13.518a2.028 2.028 0 002.028-2.028v-7.437a2.028 2.028 0 00-2.028-2.028h-4.055V6.028A2.028 2.028 0 0015.463 4zM6.676 20.9V6.028a1.352 1.352 0 011.352-1.352h7.435a1.352 1.352 0 011.352 1.352V22.25h-2.7v-4.394a.338.338 0 00-.338-.338H9.718a.338.338 0 00-.338.338v4.394H8.028a1.352 1.352 0 01-1.352-1.35zm3.38 1.352v-4.058h3.38v4.056zm11.49-10.141a1.352 1.352 0 011.354 1.352V20.9a1.352 1.352 0 01-1.352 1.352h-4.057V12.111z"
                data-name="Path 156"
                ></path>
                <path
                d="M12.338 12.028h1.352a.338.338 0 00.338-.338v-1.352A.338.338 0 0013.69 10h-1.352a.338.338 0 00-.338.338v1.352a.338.338 0 00.338.338zm.338-1.352h.676v.676h-.676zm-.338 4.731h1.352a.338.338 0 00.338-.338v-1.351a.338.338 0 00-.338-.338h-1.352a.338.338 0 00-.338.338v1.352a.338.338 0 00.338.337zm.338-1.352h.676v.676h-.676zm1.014 4.731a.338.338 0 00.338-.338V17.1a.338.338 0 00-.338-.338h-1.352A.338.338 0 0012 17.1v1.352a.338.338 0 00.338.338zm-1.014-1.352h.676v.676h-.676zm5.069-5.407H19.1a.338.338 0 00.338-.338v-1.351A.338.338 0 0019.1 10h-1.355a.338.338 0 00-.338.338v1.352a.338.338 0 00.338.338zm.338-1.352h.676v.676h-.676zm-.338 4.731H19.1a.338.338 0 00.338-.338v-1.35a.338.338 0 00-.338-.338h-1.355a.338.338 0 00-.338.338v1.352a.338.338 0 00.338.337zm.338-1.352h.676v.676h-.676zm-.338 4.731H19.1a.338.338 0 00.338-.338V17.1a.338.338 0 00-.338-.338h-1.355a.338.338 0 00-.338.338v1.352a.338.338 0 00.338.335zm.338-1.352h.676v.676h-.676zm5.745 2.028h1.352a.338.338 0 00.338-.338v-1.35a.338.338 0 00-.338-.338h-1.352a.338.338 0 00-.338.338v1.352a.338.338 0 00.338.338zm.338-1.352h.676v.676h-.676zm-.338 4.731h1.352a.338.338 0 00.338-.338v-1.349a.338.338 0 00-.338-.338h-1.352a.338.338 0 00-.338.338V22.5a.338.338 0 00.338.342zm.338-1.352h.676v.676h-.676z"
                data-name="Path 157"
                transform="translate(-3.972 -3.972)"
                ></path>
            </g>
            </svg>
        )
    }
}

export default ListingsIcon