import { signOut } from "firebase/auth"
import { useState } from "react"
import { auth } from "../firebase/config"
import { useAuthContext } from "./useAuthContext"

export const useLogout = () => {
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const logout = async () => {
    setIsPending(true)
    try {
      await signOut(auth)
      dispatch({ type: "LOGOUT" })
      setIsPending(false)
    } catch (err) {
      setIsPending(false)
    }
  }

  return { logout, isPending }
}
