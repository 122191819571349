import styled from "styled-components"

export const Container = styled.section`
  display: flex;
  gap: 50px;
  min-height: 500px;
  padding: 20px;
`

export const SidebarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 30%;
  padding: 20px 0;

  h4 {
    font-size: 0.88rem;
    opacity: 70%;
  }

  button.logout {
    margin-top: 20px;
    border: 1px solid var(--lightGreen);
    color: var(--lightGreen);
    width: 100%;
    padding: 1rem 0;
    background: none;
    border-radius: 5px;
    font-size: 0.77rem;
    margin-bottom: 70px;
  }

  @media (max-width: 992px) {
    position: fixed;
    left: ${({ isOpen }) => (isOpen ? "0px" : "-100%")};
    top: 0;
    height: 100vh;
    z-index: 3;
    background-color: white;
    width: 70%;
    border-right: 1px solid #dddddd;
    transition: 0.3s ease-in;
  }
`

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #dddddd;
  border-radius: 5px;

  @media (max-width: 992px) {
    overflow-y: scroll;
    overflow-x: hidden;
    flex-grow: 1;
  }
`

export const SidebarToggle = styled.div`
  display: none;
  position: fixed;
  left: ${({ isOpen }) => (isOpen ? "70%" : "0")};
  top: 50%;
  padding: 5px 10px;
  background-color: white;
  border: 1px solid #dddddd;
  border-left: none;
  transition: 0.3s ease-in;

  svg {
    transform: ${({ isOpen }) =>
      isOpen ? "rotateZ(-180deg)" : "rotateZ(0deg)"};
    transition: 0.3s ease-in;
  }

  @media (max-width: 992px) {
    display: block;
  }
`

export const SidebarNotification = styled.div`
  width: calc(100% + 4px);
  transform: translate(-2px, -2px);
  border-radius: 5px;
  padding: 20px 5%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img,
  p,
  span {
    margin-bottom: 5px;
  }

  p {
    font-weight: 500;
    font-size: 0.88rem;
  }

  span.text {
    font-weight: 200;
    font-size: 0.77rem;
  }

  button {
    border: none;
    background: none;
    color: var(--lightGreen);
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.77rem;

    span {
      margin: 0;
    }
  }
`

export const SideBarItem = styled.button`
  width: 90%;
  padding: 30px 0;
  display: flex;
  margin: 0 auto;
  gap: 5px;
  background: none;
  border: none;
  text-align: left;
  border-bottom: 1px solid #dddddd;

  &:last-child {
    border-bottom: unset;
  }

  &:focus {
    outline: none;
  }

  img {
    align-self: start;
  }

  .text {
    flex-grow: 1;

    p {
      margin-bottom: 5px;
      line-height: 1;
      font-weight: 500;
      color: ${({ isActive }) => (isActive ? "var(--lightBlue)" : "black")};
      font-size: 0.88rem;
    }

    span {
      font-size: 0.77rem;
      font-weight: 200;
    }
  }
  .arrow {
    align-self: center;
  }
`

export const ContentContainer = styled.div`
  padding-top: 35px;
  flex-grow: 1;
`

export const RightContent = styled.div`
  padding: 20px 0;
  h3 {
    font-weight: bold;
    font-size: 2.5rem;
  }
  p.subheading {
    color: var(--lightText);
    font-size: 0.88rem;
    opacity: 70%;
  }
  .button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 576px) {
      align-items: center;
    }

    button.save {
      padding: 5px 40px;
      background-color: var(--lightGreen);
      color: white;
      border: none;
      border-radius: 5px;

      @media (max-width: 576px) {
        display: block;
        width: 100%;
      }
    }
  }
`

export const PhotoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;

  @media (max-width: 576px) {
    flex-direction: column;
  }

  img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
  }
  .replace {
    label {
      display: block;
      padding: 10px 20px;
      border-radius: 5px;
      color: white;
      font-size: 0.72rem;
      background-color: var(--lightGreen);
      border: 1px solid var(--lightGreen);
    }
    input {
      display: none;
    }
  }
  .remove {
    button {
      background: none;
      border: 1px solid var(--lightGreen);
      padding: 10px 20px;
      border-radius: 5px;
      font-size: 0.72rem;
      color: var(--lightGreen);
    }
    button.disabled {
      opacity: 70%;
    }
  }
`

export const PaymentContainer = styled.div`
  padding: 20px 0;

  .payments {
    .add {
      color: var(--lightGreen);
      border: 1px solid var(--lightGreen);
      background: none;
      border-radius: 5px;
      padding: 10px 40px;
      font-size: 0.9rem;
    }

    .credits {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px 0;

      .credit {
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;

        p.card-text {
          color: #4f4f4f;
          font-size: 0.66rem;
          margin-bottom: 10px;
          font-weight: bold;
        }
        p.card-number {
          font-size: 0.77rem;
          color: #4f4f4f;
          margin: 0;
        }
        .delete {
          border: none;
          background: none;
          color: var(--lightGreen);
          font-size: 0.8rem;
        }
      }
    }
  }

  .add-payments {
    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      .save {
        background-color: var(--lightGreen);
        padding: 10px 40px;
        font-size: 0.9rem;
        color: white;
        border: none;
        border-radius: 5px;
      }
      .back {
        border: 1px solid var(--lightGreen);
        background: none;
        padding: 10px 40px;
        font-size: 0.9rem;
        color: var(--lightGreen);
        border-radius: 5px;
      }
    }
  }
`
export const ParametersContainer = styled.div`
  button.change {
    color: var(--lightGreen);
    padding: 10px 40px;
    border: 1px solid var(--lightGreen);
    border-radius: 5px;
    font-size: 0.77rem;
    background: none;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 40px;

    button {
      background-color: var(--lightGreen);
      padding: 10px 3rem;
      font-size: 0.72rem;
      color: white;
      border: none;
      border-radius: 5px;
    }
  }

  .parameters {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    .parameter {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dddddd;
      padding: 20px 0;

      .text {
        p {
          margin: 0;
        }
        p.sub-head {
          color: #9b9b9b;
          font-size: 0.88rem;
        }
      }
    }
  }
`
export const PasswordContainer = styled.div`
  padding: 20px 0;

  .password-container {
    margin-top: 30px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    .save {
      background-color: var(--lightGreen);
      border: none;
      border-radius: 5px;
      padding: 5px 60px;
      color: white;
      font-size: 0.9rem;
    }

    .back {
      background: none;
      border: 1px solid var(--lightGreen);
      border-radius: 5px;
      color: var(--lightGreen);
      padding: 5px 60px;
      font-size: 0.9rem;
    }
  }
`
export const SwitchContainer = styled.div`
  input {
    display: none;
  }
  label {
    display: block;
    width: 60px;
    height: 30px;
    background-color: ${({ isActive }) =>
      isActive ? "var(--lightBlue)" : "#dddddd"};
    border-radius: 30px;
    position: relative;
    transition: 200ms ease;

    &:before {
      content: "";
      position: absolute;
      transition: 200ms ease;
      top: 0;
      transform: ${({ isActive }) =>
        isActive ? "translateX(100%) translateY(-2px)" : "translateY(-2px)"};
      width: 34px;
      height: 34px;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      background-color: white;
    }
  }
`
export const ListingsContainer = styled.div`
  a.add {
    background: none;
    border-radius: 5px;
    border: 1px solid var(--lightGreen);
    color: var(--lightGreen);
    padding: 10px 40px;
    font-size: 0.77rem;
    text-decoration: none;
  }

  .listings {
    display: flex;
    flex-direction: column;

    .listing {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0;
      border-bottom: 1px solid #dddddd;

      @media (max-width: 1200px) {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
      }
      .info {
        display: flex;
        align-items: center;
        gap: 5px;

        @media (max-width: 768px) {
          flex-direction: column;
          width: 100%;
        }

        .featured-img {
          width: 250px;
          height: 150px;
          object-fit: cover;
          border-radius: 15px;
        }

        .text {
          display: flex;
          flex-direction: column;
          gap: 3px;

          @media (max-width: 768px) {
            width: 100%;
            align-items: center;
          }
          h5 {
            margin: 0;
            font-size: 1.5rem;
            font-weight: bold;

            a {
              text-decoration: none;
              color: black;
            }
          }
          p.location {
            margin-bottom: 5px;
            color: var(--lightText);
            font-size: 1.05rem;
          }

          .amenities {
            background-color: rgba(71, 123, 17, 0.2);
            padding: 10px;
            color: var(--darkGreen);
            border-radius: 5px;
            font-weight: bold;
            font-size: 0.88rem;
            width: fit-content;
          }
        }
      }
      .edit {
        display: flex;
        flex-direction: column;
        gap: 10px;

        button.delete {
          background: none;
          border: 1px solid var(--lightGreen);
          color: var(--lightGreen);
          padding: 10px 20px;
          border-radius: 5px;
          font-size: 0.72rem;
        }

        a {
          background-color: var(--lightGreen);
          color: white;
          border: none;
          border-radius: 5px;
          padding: 10px 20px;
          font-size: 0.72rem;
          text-decoration: none;
          text-align: center;
        }
        @media (max-width: 1200px) {
          width: 100%;
          a {
            width: 100%;
          }
        }
      }
    }
  }
`
export const ContactContainer = styled.div`
  margin-top: 30px;
  .error{
    color:red;
  }
  .submit{
    text-align:right;
    input{
      border: none;
      background-color: var(--lightGreen);
      border-radius: 5px;
      padding: 10px 40px;
      color: white;
    }

  }
  .submit-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: flex-end;

    button {
      border: none;
      background-color: var(--lightGreen);
      border-radius: 5px;
      padding: 10px 40px;
      color: white;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .notification-container {
    margin-top: 10px;
    color: var(--lightGreen);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`
export const EventContainer = styled.div`
  .confirm-delete {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;

    .confirm-delete-content {
      background-color: white;
      padding: 40px;
      width: 50%;
      border-radius: 10px;
      text-align: center;

      @media (max-width: 768px) {
        width: 80%;
      }

      .delete-buttons-container {
        margin-top: 40px;
        display: flex;
        gap: 10px;
        justify-content: center;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        button.cancel {
          background: none;
          border: 1px solid var(--lightGreen);
          border-radius: 10px;
          padding: 10px 30px;
          color: var(--lightGreen);
        }

        button.delete {
          background-color: var(--lightGreen);
          border: none;
          border-radius: 10px;
          padding: 10px 30px;
          color: white;
        }
      }
    }
  }

  .add-event {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      padding: 1rem 4rem;
      color: var(--lightGreen);
      border: 1px solid var(--lightGreen);
      border-radius: 5px;
      background: none;
      font-size: 0.77rem;
    }
  }
  .events-container {
    padding: 20px 0;
    display: flex;
    flex-direction: column;

    .event {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0;
      border-bottom: 1px solid #dddddd;

      &:last-child {
        border-bottom: none;
      }

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
      }

      .content {
        display: flex;
        gap: 10px;

        .event-img {
          max-width: 250px;
          max-height: 250px;
          object-fit: cover;
        }

        img {
          border-radius: 14px;
        }

        @media (max-width: 576px) {
          flex-direction: column;
        }

        .text {
          width: 50%;

          @media (max-width: 576px) {
            width: 100%;
          }

          h4 {
            font-size: 1.2rem;
            margin-bottom: 5px;
            font-weight: bold;
          }

          .location {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-bottom: 5px;

            span {
              font-weight: 400px;
              font-size: 0.83rem;
              color: #7e7e7e;
            }
          }

          p {
            margin-bottom: 5px;
            font-size: 1rem;
            color: #7e7e7e;
          }
        }
      }
      .edit {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        gap: 5px;
        flex-shrink: 0;
        @media (max-width: 768px) {
          width: 100%;
        }
        button {
          background-color: var(--lightGreen);
          border: none;
          border-radius: 5px;
          color: white;
          padding: 1rem 5px;
          width: 100%;
          font-size: 0.72rem;
        }
        button.delete {
          background: none;
          border: 1px solid var(--lightGreen);
          color: var(--lightGreen);
        }
      }
    }
  }
`
export const AddEventContainer = styled.div`
  .inputs-container {
    p.upload-text {
      font-size: 0.66rem;
      font-weight: bold;
      color: var(--lightGreen);
      margin-bottom: 0;
    }
    .dropPhotos {
      width: 100%;
      max-width: unset;
      border: 1px dotted var(--lightGreen);
      margin-bottom: 5px;
      span {
        color: var(--lightGreen);
      }

      svg {
        path {
          fill: var(--lightGreen);
        }
      }
    }
  }
  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 20px 0;

    @media (max-width: 576px) {
      flex-direction: column;

      button {
        width: 100%;
      }
    }

    button.back {
      background: none;
      color: var(--lightGreen);
      padding: 5px 50px;
      border: 1px solid var(--lightGreen);
      border-radius: 5px;
    }

    button.create {
      background-color: var(--lightGreen);
      padding: 5px 30px;
      border: 1px solid var(--lightGreen);
      border-radius: 5px;
      color: white;
    }

    button.create.disabled {
      background-color: var(--lightGreen);
      padding: 5px 30px;
      border: 1px solid var(--lightGreen);
      border-radius: 5px;
      color: white;
      opacity: 70%;
    }
  }
`
export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .notification {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 576px) {
      flex-direction: column;
    }

    .left-side {
      display: flex;
      align-items: flex-start;
      gap: 5px;
      width: 85%;

      svg {
        flex-shrink: 0;
      }

      h5 {
        font-size: 0.88rem;
        margin-bottom: 10px;
      }

      span {
        font-size: 0.66rem;
        margin-bottom: 10px;
        display: block;
      }

      p {
        font-size: 0.77rem;
        opacity: 70%;
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 576px) {
        flex-direction: row;
      }

      button.delete {
        background: none;
        border: none;
        font-size: 0.77rem;
      }

      button.read-more {
        background: none;
        border: none;
        font-size: 0.77rem;
        color: var(--lightGreen);
      }
    }
  }
`
export const InboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .reply-box {
    background-color: #f7f7f7;
    border-radius: 5px;
    padding: 10px;
    .content {
      img {
        width: 42px;
        height: 42px;
        object-fit: cover;
        margin-bottom: 10px;
        display: block;
        border-radius: 50%;
      }

      span {
        font-size: 0.66rem;
        margin-bottom: 10px;
        color: var(--lightGreen);
      }

      textarea {
        border: none;
        background: none;
        width: 100%;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .message.sent {
    background-color: #f7f7f7;
  }

  .message {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;

    .img-container {
      display: flex;
      align-items: center;
      gap: 15px;

      img {
        width: 42px;
        height: 42px;
        object-fit: cover;
        margin-bottom: 10px;
        display: block;
        border-radius: 50%;
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
      }
      p.user-name {
        font-weight: bold;
      }
    }

    .message-btn {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      button {
        background: none;
        border: none;
        padding: none;
        font-size: 0.77rem;
      }
    }

    .content {
      margin-top: 0px;
      img {
        width: 42px;
        height: 42px;
        object-fit: cover;
        margin-bottom: 10px;
        display: block;
        border-radius: 50%;
      }

      h5 {
        font-size: 0.88rem;
        font-weight: 400;
        margin-bottom: 4px;
      }

      span {
        font-size: 0.66rem;
        margin-bottom: 10px;
      }
      p {
        font-size: 0.77rem;
        font-weight: 400;
        opacity: 70%;
      }
    }

    .view-button {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        color: var(--lightGreen);
        background: none;
        border: none;
        font-size: 0.77rem;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button.back {
      background-color: transparent;
      border: 1px solid var(--lightGreen);
      color: var(--lightGreen);
    }

    button {
      background-color: var(--lightGreen);
      padding: 1rem 3rem;
      border: none;
      border-radius: 5px;
      color: white;
      font-size: 0.77rem;
    }
  }
`
export const ToBeDeletedPopup = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    background-color: white;
    padding: 30px 60px;
    border-radius: 15px;
    max-width: 70%;
    text-align: center;

    h3 {
      font-size: 2rem;
    }

    .buttons {
      display: flex;
      justify-content: space-around;
      margin-top: 30px;

      @media (max-width: 576px) {
        flex-direction: column;
        gap: 10px;
      }

      button {
        padding: 10px 30px;
        border-radius: 12px;
      }

      button.cancel {
        background: none;
        border: 1px solid var(--lightGreen);
        color: var(--lightGreen);
      }

      button.delete {
        background-color: var(--lightGreen);
        border: none;
        color: white;
      }
    }
  }
`
